@mixin respond($breakpoint)
    @if $breakpoint == phone 
        @media only screen and (max-width: 600px)
            @content

    @if $breakpoint == tab-old
        @media only screen and (max-width: 1050px)
            @content

    @if $breakpoint == tab-port
        @media only screen and (max-width: 1500px)
            @content
            

    @if $breakpoint == tab-land
        @media only screen and (max-width: 1800px)
            @content

    @if $breakpoint == big-screen
        @media only screen and (min-width: 2000px)
            @content

