.modal_window
  background-color: rgba(0,0,0,.5)
  height: 100vh
  position: absolute
  display: none
  &.active
    display: block
  &__wrapper
    position: fixed
    z-index: 100000
    right: 50%
    bottom: 50%
    transform: translateX(50%) translateY(50%)
    height: 90vh
    padding: 7rem
    border-radius: 1rem
    border: 0.2rem dashed #fff
    background: rgba(255,255,255,0.4)
    @include respond(phone)
      height: 70vh
.img_modal
  border-radius: 1rem
  height: 100%
  aspect-ratio: 9 / 16
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px
.close_modal
  cursor: pointer
  position: absolute
  height: 3rem
  width: 3rem
  top: 2rem
  right: 2.5rem
  background-image: url(/img/modal_close.svg)
  background-repeat: no-repeat
  background-size: cover
.modal_bg
  background: rgba(0,0,0,.5)
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 99999