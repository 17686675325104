#baner
    height: 100vh
    background-image: url(../img/baner.jpg)
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    display: flex
    align-items: center
    justify-content: center
.baner_wrapper
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 7rem
.baner_logo
    width: 30rem
    height: 40rem
    filter: drop-shadow(0 0 10rem rgba(0,0,0,.25))
    background-image: url(../img/logo.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    margin-bottom: 3.5rem
    @include respond(tab-land)
        width: 20rem
        height: 25rem
        margin-bottom: 1rem
    @include respond(tab-port)
        width: 20rem
        height: 25rem
        margin-bottom: 3.5rem
    @include respond(phone)
        margin: 0
.baner_title_title
    color: $light
    text-align: center
    text-shadow: 0px .5rem 12.54rem 0px #000
    font-family: 'Oranienbaum', serif
    font-size: 6.85rem
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: uppercase
    margin-bottom: 3rem
    @include respond(tab-land)
        font-size: 5.5rem
        margin-bottom: 2rem
    @include respond(tab-port)
        font-size: 4.5rem
.baner_title_descr
    color: $light
    text-align: center
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: 'Open Sans', sans-serif
    font-size: 3.78rem
    font-style: normal
    font-weight: 400
    line-height: normal
    margin-bottom: 7.95rem
    @include respond(tab-land)
        font-size: 2.78rem
        margin-bottom: 5rem
    @include respond(tab-port)
        font-size: 3.5rem
        margin-bottom: 4.5rem
.baner_btn
    color: $light
    text-align: center
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: 'Oranienbaum', serif
    font-size: 3rem
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: uppercase
.baner_btn_menu
    padding: 3.2rem 6rem 3.15rem 6rem
    border-radius: .685rem
    border: 1px solid $light
    margin-right: 7.95rem
    transition: all .5s
    &:hover
        transition: all .5s
        background-color: rgba(9, 66, 34, 0.5)
        border: 1px solid $red
.baner_btn_rezerv
    padding: 3.2rem 6rem 3.15rem 6rem
    border-radius: .685rem
    color: $light
    border: none
    text-transform: uppercase
    background-color: $red
    border: 1px solid rgba(0,0,0,0)
    transition: all .5s
    &:hover
        transition: all .5s
        background-color: rgba(9, 66, 34, 0.5)
        border: 1px solid rgba(9, 66, 34, 0.5)
