.navigation_desctop
    background-color: $red
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1.65rem 8.5rem 2.5rem 8.5rem
    position: fixed
    top: 0
    width: 100%
    z-index: 1000
    transition-duration: .5s
    &.small
        transition-duration: .5s
        padding: 1rem 8.5rem 1rem 8.5rem
.navigation_mobile
    background-color: $red
    display: flex
    justify-content: space-between
    align-items: flex-start
    position: fixed
    top: 0
    right: -100%
    width: 70%
    height: 100vh
    z-index: 990
    padding: 90px 22px 22px 22px
    transition-duration: 1s
    &.active
        right: 0
        transform: translateX(0)
        transition-duration: 1s
.nav_burger
    width: 35px
    height: 20px
    background-image: url(../img/burger.svg)
    background-size: contain
    background-position: center
    background-repeat: no-repeat
.nav_wrapper
    display: flex
    //gap: 6.85rem
    justify-content: space-between
    flex: 0 1 40%
    @include respond(phone)
        flex-direction: column
        gap: 20px
    &__items
        color: $light
        leading-trim: both
        text-edge: cap
        text-shadow: 0px .5rem 10rem 0px #000
        font-family: Circe
        font-size: 1.9rem
        font-style: normal
        font-weight: 400
        line-height: 2rem
        text-transform: uppercase
        text-wrap: nowrap
        cursor: pointer
        overflow: hidden
        @include respond(phone)
            font-size: 14px
            padding: .4rem 0
.nav_logo
    width: 9.6rem
    height: 9.6rem
    transition-duration: .5s
    &.small
        transition-duration: .5s
        width: 5rem
        height: 5rem
        @include respond(phone)
            width: 72px            
    & img
        width: 100%
        height: 100%
        @include respond(phone)
            width: 150px
    @include respond(phone)
        display: flex
        justify-content: center
        align-items: center
        width: 72px
        justify-content: normal
.nav_social
    //margin-right: 9rem
    transition-duration: .5s
    &:hover
        transition-duration: .5s
        transform: scale(1.2)
        filter: blur(1px)
.nav_info
    display: flex
    align-items: center
    justify-content: space-between
    flex: 0 1 40%
.nav_adress_icon
    margin-right: 1.37rem
.nav_tel
    display: flex
    gap: 1.37rem
    color: $light
    text-align: center
    leading-trim: both
    text-edge: cap
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: Circe
    font-size: 2rem
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: uppercase
    //margin-right: 7.26rem
    text-wrap: nowrap
    cursor: pointer
    @include respond(phone)
        font-size: 14px
        line-height: 16px
.nav_adress
    display: flex
    gap: 1.37rem
    @include respond(phone)
        flex-direction: column
        align-items: flex-start
.nav_adress__item
    color: $light
    leading-trim: both
    text-edge: cap
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: Circe
    font-size: 2rem
    font-style: normal
    font-weight: 400
    line-height: 2rem
    text-transform: uppercase
    text-wrap: nowrap
    @include respond(phone)
        font-size: 14px
        line-height: 16px
.nav_mobile_schedule
    display: flex
    justify-content: space-between
    align-items: flex-start
    