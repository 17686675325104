#about
    height: 100%
    background-color: $red
    display: grid
    grid-template-columns: 1fr 55%
    @include respond(phone)
        grid-template-rows: 1fr 380px
        grid-template-columns: 1fr
        height: 100%
.about_title
    padding: 3.3rem 6rem
.about_img_wrapper
    min-width: 0
    overflow: hidden
    position: relative
.about_img
    & img
        width: 100%
        height: 100%
        object-fit: cover
.about_title_wrapper
    width: 100%
    height: 100%
    border-style: solid
    border-image-width: 10rem
    border-image-source: url(../img/about_border.svg)
    border-image-slice: 100
    border-image-repeat: stretch
    padding: 10rem 6.9rem 12.7rem
    @include respond(phone)
        border-image-width: 20rem
