*, ::after, ::before
    margin: 0
    padding: 0
    box-sizing: border-box
*
    -webkit-tap-highlight-color: transparent
ul
    margin: 0
a
    text-decoration: none
    color: $light

li
    list-style-type: none    

body.active
    @include respond(phone)
        overflow: hidden

.bg_nav
    background: rgba(0,0, 0, 0.5)
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100%
    z-index: 10
    display: none
    &.active
        display: block
html
    font-size: 50%
    scroll-behavior: smooth
    @include respond(tab-land)
        font-size: 55.00%
    @include respond(tab-port)
        font-size: 45%
    @include respond(tab-old)
        font-size: 30%        
    @include respond(phone)
        font-size: 4.8px
    @include respond(big-screen)
        //font-size: 75%
