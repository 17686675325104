@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Oranienbaum&display=swap')

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-Regular.eot')
	src: local('Circe'), local('Circe-Regular'), url('../fonts/Circe/Circe-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Regular.woff') format('woff'), url('../fonts/Circe/Circe-Regular.ttf') format('truetype')
	font-weight: normal
	font-style: normal

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-ExtraLight.eot')
	src: local('Circe ExtraLight'), local('Circe-ExtraLight'), url('../fonts/Circe/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-ExtraLight.woff') format('woff'), url('../fonts/Circe/Circe-ExtraLight.ttf') format('truetype')
	font-weight: 200
	font-style: normal

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-Thin.eot')
	src: local('Circe Thin'), local('Circe-Thin'), url('../fonts/Circe/Circe-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Thin.woff') format('woff'), url('../fonts/Circe/Circe-Thin.ttf') format('truetype')
	font-weight: 100
	font-style: normal

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-Light.eot')
	src: local('Circe Light'), local('Circe-Light'), url('../fonts/Circe/Circe-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Light.woff') format('woff'), url('../fonts/Circe/Circe-Light.ttf') format('truetype')
	font-weight: 300
	font-style: normal

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-Bold.eot')
	src: local('Circe Bold'), local('Circe-Bold'), url('../fonts/Circe/Circe-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-Bold.woff') format('woff'), url('../fonts/Circe/Circe-Bold.ttf') format('truetype')
	font-weight: bold
	font-style: normal

@font-face
	font-family: 'Circe'
	src: url('../fonts/Circe/Circe-ExtraBold.eot')
	src: local('Circe ExtraBold'), local('Circe-ExtraBold'), url('../fonts/Circe/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/Circe/Circe-ExtraBold.woff') format('woff'), url('../fonts/Circe/Circe-ExtraBold.ttf') format('truetype')
	font-weight: 800
	font-style: normal

.oh_title
	color: $text
	text-shadow: 0px .5rem 10rem 0px #000
	font-family: 'Oranienbaum', serif
	font-size: 8.75rem
	font-style: normal
	font-weight: 400
	line-height: normal
	text-transform: uppercase
	@include respond(phone)
		font-size: 7rem
.oh_description
	color: $light
	text-shadow: 0px .5rem 10rem 0px #000
	font-family: 'Open Sans', sans-serif
	font-size: 2.4rem
	font-style: normal
	font-weight: 350
	line-height: 120%
	@include respond(phone)
		font-size: 14px
		line-height: 130%
