#contact
    background-color: #070707
    display: grid
    grid-template-columns: 1fr 1fr
    @include respond(phone)
        display: flex
        flex-direction: column-reverse
.contact_info
    padding: 7.1rem 7.1rem 7.1rem 10rem
    display: flex
    flex-direction: column
    justify-content: space-between
    &_oh
        margin-bottom: 17rem
        @include respond(phone)
            margin-bottom: 40px
.contact_info_info
    display: flex
    justify-content: space-between
    margin-bottom: 8rem
    @include respond(phone)
        flex-direction: column
        margin-bottom: 40px
.fs-27
    font-size: 2.7rem
    line-height: 114.286%
.info_schedule
    color: $light
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: "Open Sans",sans-serif
    font-size: 2.7rem
    font-style: normal
    font-weight: 400
    line-height: 164.286%
    text-transform: uppercase
    @include respond(phone)
        font-size: 14px
.contact_rezerv
    color: $broun
    text-align: center
    font-family: 'Oranienbaum', serif
    font-size: 3.7rem
    font-style: normal
    font-weight: 400
    line-height: normal
    text-transform: uppercase
    padding: 24px 47px 23px 48px
    border-radius: 0.685rem 
    width: 41rem
    margin-bottom: 20rem
    background: #D1AA67
    border: rgba(0, 0, 0, 0)
    transition: .5s
    &:hover
        transition: .5s
        background: rgba(9, 66, 34, 0.5)
        border: $light
        color: $light
    @include respond(phone)
        font-size: 20px
        padding: 16px 14.5px
.contact_social
    display: flex
    justify-content: flex-start
    gap: 2rem
    align-items: center
.contact_social_up
    color: $light
    text-align: center
    font-family: "Open Sans",sans-serif
    font-size: 1.5rem
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    padding: 2rem 1.5rem
    border: .1rem solid $light
    border-radius: .3rem
    transition: all .5s
    &:hover
        transition: all .5s
        background-color: rgba(9, 66, 34, 0.5)
        border: .1rem solid $red
    @include respond(phone)
        font-size: 10px
    &::after
        content: ""
        padding-left: 3rem
        background-image: url(../img/Arrow.svg)
        background-repeat: no-repeat
        background-size: contain
        background-position: center
        @include respond(phone)
            padding-left: 15px
.info_adress
    display: flex
    flex-direction: column
    justify-content: space-between
    @include respond(phone)
        margin-bottom: 30px
.contact_social_social
    width: 4.8rem
    height: 4.8rem
    transition-duration: .5s
    &:hover
        transition-duration: .5s
        transform: scale(1.2)
        filter: blur(1px)
    & img
        width: 100%
        height: 100%
.contact_mail
    color: $light
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: "Open Sans",sans-serif
    font-size: 2.4rem
    font-style: normal
    font-weight: 400
    line-height: 151.232%
    text-transform: uppercase
.contact_maps
    @include respond(phone)
        height: 524px !important
.nav_adress__item
    display: flex
    @include respond(phone)
        margin-bottom: 30px