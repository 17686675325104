#menu
    padding-top: 10rem
    background-color: #0e0e0e
    background-image: url(../img/menu_bg.png)
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    flex-direction: column
    align-items: center
    min-width: 0
.btn_rezerv
    display: flex
    justify-content: center
.menu_items
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    min-width: 0
.menu_items_img
    width: 50rem
    height: 53rem
    border-style: solid
    border-image-width: 10rem
    border-image-source: url(../img/menu_border.svg)
    padding: 2rem
    border-image-slice: 100
    border-image-repeat: stretch
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    margin-bottom: 3rem
    @include respond(phone)
        width: 300px
        height: 310px
.test_img
    clip-path: url("#border")
.menu_items_img img
    height: 100%
    width: 100%
    object-fit: cover
.menu_items_title
    color: $text
    text-align: center
    text-shadow: 0px .5rem 10rem 0px #000
    font-family: Oranienbaum
    font-size: 4.2rem
    font-style: normal
    font-weight: 400
    line-height: 106.667%
    text-transform: uppercase
    margin-bottom: 2.5rem
.menu_items_price
    color: #fff
    text-align: center
    leading-trim: both
    text-edge: cap
    font-family: Circe
    font-size: 2.5rem
    font-style: normal
    font-weight: 400
    line-height: 88.889%
    @include respond(phone)
        font-size: 16px
.menu_btn 
    display: inline-block
.swiper-button-prev, .swiper-button-next
    color: #D1AA67