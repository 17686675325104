.btn_main, .btn_phone_main
    position: fixed
    bottom: 7%
    right: 5%
    z-index: 999
    @include respond(phone)
        bottom: 5%
        right: 2%
.btn_main
    box-shadow: 0 0 10rem 0 rgba(0,0,0,.25)
    padding: 1.1rem 2rem
    background-color: $red
    border: none
    color: $light
    font-family: Oranienbaum,serif
    font-size: 2.5rem
    font-style: normal
    font-weight: 700
    text-transform: uppercase
    border-radius: .5rem
    opacity: 0
    transition-duration: .5s
    &.active
        transition-duration: .5s
        opacity: 1
.btn_phone_main
    width: 120px
    height: 120px
    background-image: url(../img/phone_mobile.svg)
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    filter: drop-shadow(0 0 10rem rgba(0,0,0,.25))
    opacity: 0
    transition-duration: .5s
    &.active
        transition-duration: .5s
        opacity: 1