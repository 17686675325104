#feedback
    background-color: #070707
    padding: 7rem 10rem
    display: flex
    align-items: center
    justify-content: space-between
    @include respond(phone)
        padding: 7rem 34px
        align-items: flex-start
        justify-content: space-between
        flex-direction: column
        gap: 3rem
.feedback_wrapper
    display: flex
    justify-content: flex-start
    gap: 7rem
.feedback_items
    height: 9rem
    width: 9rem
    display: flex
    align-items: flex-end
    justify-content: center
    transition-duration: .5s
    &:hover
        transition-duration: .5s
        transform: scale(1.1)
        filter: grayscale(80%)
    @include respond(phone)
        width: 52px
        height: 52px
    & img
        width: 100%
        height: 100%
.contact_social_up
    cursor: pointer