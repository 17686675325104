#sale
    background-color: #D1AA67
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    padding-top: 10rem
.sale_wrapper
    display: flex
    justify-content: space-between
    padding-bottom: 10rem
    min-width: 0
    overflow: hidden
    @include respond(phone)
        flex-direction: column
        min-width: 0
.sale_wrapper_items
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    width: 40rem
    height: 70rem
    border-radius: 10px
    border: .2rem dashed #fff
    background: rgba(255, 255, 255, 0.15)
    margin: 0 auto
    padding: 1.7rem 2.4rem
    @include respond(phone)
        width: 50rem
        height: 80rem
.sale_wrapper_items__img
    width: 37rem
    height: 67rem
    border-radius: 1rem
    overflow: hidden
    aspect-ratio: 1/16
    @include respond(phone)
        width: 45rem
        height: 75rem
    & img
        width: 100%
        height: 100%
.sale_wrapper_items__day
    color: $red
    text-align: center
    font-family: Oranienbaum
    font-size: 4.5rem
    font-style: normal
    font-weight: 400
    line-height: 100%
    letter-spacing: 1.6px
    text-transform: uppercase
.sale_wrapper_items__description
    color: $red
    text-align: center
    font-family: Open Sans
    font-size: 2.4rem
    font-style: normal
    font-weight: 600
    line-height: 111.111%