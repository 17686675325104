.hide_mobile
    @include respond(phone)
        display: none !important
.visible_mobile
    display: none !important
    @include respond(phone)
        display: block !important
.fw-400
    font-weight: 400
.text-blk
    color: #070707
.text-wht
    color: #fff
.text_up
    text-transform: uppercase !important
.section
    padding: 0 10rem
.mb-33
    margin-bottom: 3.3rem !important
.mb-50
    margin-bottom: 5rem !important
.mb-70
    margin-bottom: 7rem !important
.mb-100
    margin-bottom: 10rem !important
.mb-mob-45
    margin-bottom: 45px !important
.mb-mob-35
    @include respond(phone)
        margin-bottom: 35px !important
.font-open
    font-family: "Open Sans",sans-serif !important

.underline a:after
    content: ""
    display: block
    width: auto
    transform: translateX(-110%)
    padding-top: .3rem
    border-bottom: .3rem solid #e8e8e8
    transition-duration: .5s
.underline a:hover::after
    transition-duration: .5s
    transform: translateX(0%)