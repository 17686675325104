#error
    background-image: url(../img/bg_404.jpg)
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    height: 100vh
.error_wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 90vh
    align-items: center
    @include respond(phone)
        grid-template-columns: 1fr
        grid-template-rows: 1fr 1fr
        gap: 60px
        grid-template-areas: "img" "text"
        justify-items: center
        padding-bottom: 45px
.error_text
    position: relative
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr
    gap: 0px 0px
    grid-auto-flow: row
    align-items: start
    grid-template-areas: "title title" "description-first description-first" "desc-second arrow" "desc-third arrow" "desc-fourth desc-fourth"
    padding-left: 18rem
    @include respond(phone)
        grid-template-rows: 90px 55px 50px 60px 1fr
        grid-area: text
        grid-template-areas: "title title" "description-first description-first" "desc-second desc-second" "desc-third desc-third" "desc-fourth desc-fourth"
        justify-items: stretch
        padding: 0 27px
.description-first
    grid-area: description-first
.desc-fourth
    grid-area: desc-fourth
.error_arrow
    grid-area: arrow
    padding: 0 2rem
    & svg
        width: 11.5rem
        height: 19.7rem
.desc-second
    grid-area: desc-second
.desc-third
    grid-area: desc-third
.error_text_title
    color: $light
    font-family: 'Oranienbaum', serif
    font-size: 5.5rem
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    margin-bottom: 3.5rem
    grid-area: title
    @include respond(phone)
        font-size: 30px
        text-align: center
.error_text_description
    color: $light
    font-family:  'Open Sans', sans-serif
    font-size: 3.3rem
    font-style: normal
    font-weight: 350
    line-height: normal
    @include respond(phone)
        font-size: 13px
        text-align: center
.btn_forvard
    color: $light
    text-align: center
    text-shadow: 0px 5.01694917678833px 125.4237289428711px rgba(0, 0, 0, 0.60)
    font-family: 'Oranienbaum', serif
    font-size: 2.2rem
    font-style: normal
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    padding: 2.4rem 2.9rem 2.3rem
    border-radius: .5rem
    background: #D75E27
    display: inline-block
    @include respond(phone)
        font-size: 15px 
.error_img
    @include respond(phone)
        grid-area: img
.error_img img
    width: 67.5rem
    height: 81rem
    @include respond(phone)
        width: 290px
        height: 310px
