#afisha
    background-image: url(../img/action_bg.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    padding-top: 10rem
.afisha_wrapper
    display: flex
    justify-content: space-between
    padding-bottom: 10rem
    min-width: 0
    overflow: hidden
.afisha_wrapper_item
    width: 50rem
    height: 73.7rem
    & img
        width: 100%
        height: 100%
        object-fit: cover